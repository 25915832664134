<template>
	<div class="login-form">
		<div class="login-form__header">
			<v-btn class="login-form__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
				<v-icon class="login-form__header-button-icon">far fa-arrow-left</v-icon>
			</v-btn>
			<h2 class="login-form__header-title">{{ $t('login.loginTitle') }}</h2>
			<div></div>
		</div>
		<form @submit.prevent="doLogin">
			<div class="login-form__inputs">
				<v-text-field
					class="login-form__input"
					:label="$t('login.email')"
					v-model="email"
					type="email"
					:color="primaryColor"
					required
				></v-text-field>
				<v-text-field
					class="login-form__input"
					:label="$t('login.password')"
					v-model="password"
					:type="show ? 'text' : 'password'"
					:color="primaryColor"
					:append-icon="show ? 'visibility' : 'visibility_off'"
					@click:append="show = !show"
					required
				></v-text-field>
				<div class="login-form__forgotten">
					<router-link :to="{ name: 'ResetPassword' }" class="login-form__link">{{ $t('login.forgotten') }}</router-link>
				</div>
			</div>
			<div class="login-form__bottom">
				<v-btn class="login-form__button" color="#2498ff" type="submit" depressed>
					{{ $t('login.submit') }}
				</v-btn>
				<div style="display: flex; justify-content: center">
					<span style="color: #575e60; font-size: 13px; padding-right: 0.5rem">{{ $t('login.no-account') }}</span>
					<router-link :to="{ name: 'SignUp' }" class="login-form__link">{{ $t('login.signupTitle') }}</router-link>
				</div>
			</div>
			<v-row class="login-form__modal" justify="center">
				<v-dialog v-model="showModal" v-if="showModal" persistent width="378">
					<v-card>
						<v-card-title class="text-h5">
							{{ $t('login.roleTitle') }}
						</v-card-title>
						<v-card-text>{{ $t('login.roleSubtitle') }}</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn class="role-selecton__button" color="#2498ff" depressed @click="setSelectedRole('EXHIBITOR')"
								>{{ $t('login.exhibitor') }}
							</v-btn>
							<v-btn class="role-selecton__button" color="#2498ff" depressed @click="setSelectedRole('VISITOR')">
								{{ $t('login.visitor') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
			<v-row class="login-form__policy-modal" justify="center">
				<v-dialog v-model="showPolicyModal" v-if="showPolicyModal" persistent scrollable max-width="50%">
					<v-card>
						<v-card-title class="text-h5">
							{{ $t('login.policyModalTitle') }}
						</v-card-title>
						<v-card-text style="padding: 0 2rem"><LegaladviceComponent /></v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="acceptPolicy()" outlined>{{ $t('login.policyModalButton') }}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</form>
	</div>
</template>

<script>
import { login, setWorkingRole } from '@/api/sessions.js';
import { acceptPolicyLogin } from '@/api/policy.js';
import { addRelationshipUserEvent } from '@/api/event.js';
import LegaladviceComponent from '@/components/legaladvice/LegalAdviceComponent.vue';

export default {
	name: 'LoginForm',
	props: {
		primaryColor: {
			type: String,
			default: '#d4af37'
		},
		frompage: Boolean,
		frompageName: String
	},

	components: { LegaladviceComponent },

	data() {
		return {
			show: false,
			email: null,
			password: null,
			eventname: null,
			loginInfo: null,
			visitorProfile: 'VISITOR',
			showModal: false,
			showPolicyModal: false,
			expiredSessionDialogName: 'expiredSession'
		};
	},
	computed: {
		getInfoKey() {
			var session = this.$store.getters.getSession;
			return session.keepSessionAliveInfoKey;
		},

		getPrimaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	mounted() {
		this.getEventName();
	},
	created() {
		this.$puiEvents.$off(`pui-modalDialog-${this.expiredSessionDialogName}-ok`);
	},

	methods: {
		getEventName: function () {
			const aux = window.location.pathname.split('/');
			this.eventname = aux[2];
		},

		async doLogin() {
			if (this.email && this.password) {
				const params = {
					usr: this.email,
					password: this.password,
					client: 'CATALOG_' + this.eventname,
					persistent: false
				};
				const loginResponse = await login(params);

				if (loginResponse.responseType === 'success') {
					delete loginResponse.responseType;
					this.loginInfo = loginResponse;

					this.$store.state.loginData = this.loginInfo;

					if (!this.loginInfo.properties.policyAccepted) {
						this.showPolicyModal = true;
					} else {
						this.continueLogin();
					}
				} else {
					this.loginInfo = null;
					this.$puiNotify.error(loginResponse, this.$t('login.error'));
				}
			}
		},

		setSelectedRole(role) {
			this.visitorProfile = role;
			this.showModal = false;

			this.continueLogin2();
		},

		continueLogin: function () {
			if (this.loginInfo.profiles.length > 1) {
				this.showModal = true;
			} else {
				if (this.loginInfo.profiles[0]) {
					this.visitorProfile = this.loginInfo.profiles[0];
				}
				this.continueLogin2();
			}
		},

		continueLogin2: async function () {
			this.setLoginInfo(this.loginInfo);
			this.requestForRole();
			await addRelationshipUserEvent(this.$store.state.eventInfo.eveneventgroupid);
			window.localStorage.setItem('workingRole', this.visitorProfile);
			window.localStorage.removeItem('anonymous_session');
			// guardamos la del nuevo usuario loggeado
			window.localStorage.setItem(this.getInfoKey, JSON.stringify(this.loginInfo));
			switch (this.visitorProfile) {
				case 'VISITOR':
				default:
					if (
						this.frompage &&
						this.frompageName !== 'SignUp' &&
						this.frompageName !== 'ResetPassword' &&
						this.frompageName !== 'ResetPasswordValidation' &&
						this.frompageName !== 'ValidateUserVisit'
					) {
						this.$router.back();
					} else this.$router.push({ name: 'Home' });
					break;
				case 'EXHIBITOR':
					if (
						this.frompage &&
						this.frompageName !== 'SignUp' &&
						this.frompageName !== 'ResetPassword' &&
						this.frompageName !== 'ResetPasswordValidation' &&
						this.frompageName !== 'ValidateUserVisit'
					) {
						this.$router.back();
					} else this.$router.push({ name: 'Home' });
					break;
			}
		},

		acceptPolicy: async function () {
			if (this.showPolicyModal) this.showPolicyModal = false;
			await acceptPolicyLogin(this.loginInfo.email);
			this.continueLogin();
		},

		setLoginInfo(loginInfo) {
			//first commit the login info, the language will be set applying some logic
			this.$store.commit('puiLoginSetInfo', loginInfo);
			//then we use the language saved, not the recieved
			const userLang = this.$store.getters.getUserLanguage;
			let lang = '';

			if (this.getEventLangs().includes(userLang)) {
				lang = userLang;
			} else {
				lang = 'es';
			}
			this.$puiI18n.locale = lang;
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { authorization: loginInfo.jwt, acceptLanguage: lang });
		},

		async requestForRole() {
			const workingRoleResponse = await setWorkingRole(this.visitorProfile);
			this.loginInfo.functionalities = workingRoleResponse.data;
			// this.setLoginInfo(this.loginInfo);
		},

		getEventLangs() {
			if (this.$store.state.eventInfo.evencataloglanguages === '*') {
				return ['en', 'es', 'ca'];
			} else {
				const langs = this.$store.state.eventInfo.evencataloglanguages.split(',');
				return langs;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.login-form {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
}

.login-form__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
	}
}

.login-form__inputs {
	padding-top: 1rem;
}

.login-form__input {
	width: 20.5rem;
}

.login-form__forgotten {
	display: flex;
	justify-content: flex-end;
}

.login-form__bottom {
	display: flex;
	flex-direction: column;
}

.login-form__button {
	color: #fff;
	margin: 1.5rem 0;
}

.login-form__link {
	color: #304ea2;
	text-align: center;
	font-size: 13px;
}

.role-selecton__button {
	color: #fff;
	padding-right: 15px !important;
	padding-left: 15px !important;
	margin: 5px;
}
</style>
