<template>
	<div v-html="legaladviceText" :key="key" class="legaladvice"></div>
</template>

<script>
import { getPolicyLang } from '@/api/policy.js';
export default {
	name: 'LegaladviceComponent',
	data() {
		return {
			legaladviceText: '',
			key: 0
		};
	},

	async created() {
		await this.getLegalAdviceText();
	},
	methods: {
		async getLegalAdviceText() {
			const lang = window.localStorage.getItem('pui9_lang');
			const data = await getPolicyLang(lang, 'legal');
			this.legaladviceText = data;
			this.key++;
		}
	},
	watch: {
		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.getLegalAdviceText();
			}
		}
	}
};
</script>

<style scoped>
.legaladvice {
	margin-top: 2%;
}
</style>
