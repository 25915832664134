import Pui9HttpRequests from 'pui9-requests';

export async function getPolicyLang(lang, type) {
	var controller = '/policy/getPolicyLang';
	const params = {
		lang: lang,
		policyType: type
	};
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		params,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function acceptPolicyLogin(email) {
	var controller = `/policy/acceptPolicyLogin?user=${email}`;

	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}
